<template>
	<div
		class="full-height pa-10-20"
	>
		<h6>{{ program.name }}</h6>

		<Search
			class="mt-10 box-ddd"
			:search="search"
			:option="search_option"

			@reset="reset"
			@click="getSearch()"
		>
		</Search>

		<div
			class="mt-10 full-height flex-column overflow-y-auto bg-white box-ddd height-8"
		>
			<div
				class="pa-10 under-line justify-space-between"
			>
				<div>
					<v-icon
						@click="prev"
					>mdi mdi-chevron-left</v-icon>

					<span class="vertical-middle prl-10">{{ search.sDate | transDate }} ~ {{ search.eDate | transDate }}</span>

					<v-icon
						@click="next"
					>mdi mdi-chevron-right</v-icon>
					<button
						class="box pa-4-10 vertical-middle"
						@click="toToday"
					>오늘</button>
				</div>
				<button
					class="box mr-10 pa-4-10 size-px-12"
					@click="toExcel"
				><v-icon small class="color-green ">mdi mdi-file-excel</v-icon> <span class="vertical-middle">엑셀 다운로드</span></button>
			</div>

			<div class="full-height justify-space-between overflow-y-auto">
				<div
					v-for="(day, index) in item_list"
					:key="'list_' + index"
					class="full-height flex-column flex-1 border-right overflow-y-auto"
				>
					<div
						class="pa-10 under-line text-center "
						:class="today == date.getLastDate(search.sDate, index * -1, '-') ? 'bg-identify' : 'bg-eee'"
					>
						{{ date.getLastDate(search.sDate, index * -1) | transDate }} ({{ day.week }})
					</div>

					<div
						class="pa-10 under-line bg-identify"
					>
						{{ day.paymentCompleteAmountSum | makeComma }} 원
						({{ day.paymentCompleteCount |makeComma}} /
						{{ day.paymentTotalCount | makeComma}})
					</div>

					<ul
						v-if="day.data"
						class="ul-odd full-height flex-column overflow-y-auto"
					>
						<li
							v-for="(item, item_index) in day.data"
							:key="'list_' + index + '_item_' + item_index"
							class="pa-10 under-line li-even justify-space-between cursor-pointer"
							@click="toDetail(item)"
						>

							<div>
								<div>
									{{ item.userName }} ({{ item.userId }})
								</div>
								<div class="mt-5">
									{{ item.amount | makeComma }} 원
									({{ item.paymentCurrentCount | makeComma }} / {{ item.paymentTotalCount | makeComma }})
								</div>
							</div>
							<v-icon
							>mdi mdi-chevron-right</v-icon>

						</li>
					</ul>
					<div
						v-else
						class="full-height flex-column justify-center overflow-y-auto"
					>
						<div class="text-center pa-20">
							<v-icon
								class="size-px-48 "
							>mdi mdi-cloud-off-outline</v-icon>
							<br/>
							<br/>
							<div class="font-weight-bold size-px-24">No Data</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<Excel
			v-if="is_excel"
			:excel_data="excel_data"

			@finish="is_excel = !is_excel"
		></Excel>

		<Modal
			:is_modal="is_modal"
			:option="modal_option"
			:top="true"
			:bottom="true"
			:slot_bottom="true"

			title="월세 납입 상세"

			@close="close"
		>
			<MonthlyPaymentDetail
				slot="modal-content"

				:item="item"
                :idx="item.monthlyPaymentIdx"
				:user="user"
				:service="item.service"
				:type="'schedule'"

				@toKakao="toKakao"
				@doConfirm="doConfirm"
				@close="close"
				@setNotify="setNotify"
			></MonthlyPaymentDetail>
		</Modal>

		<Modal
			:is_modal="is_modal3"
			:option="modal_option3"
			:top="true"

			title="카카오톡 링크 결제"
			click_name="결제 링크 보내기"
			cancel_name="취소"
			width="380px"

			@close="close"
			@cancel="toDetail(item)"
		>
			<MonthlyPaymentkakao
				slot="modal-content"

				:item="item"
				:user="user"
				:service="item.service"
				:type="'schedule'"

				@success="close"
				@cancel="toDetail(item)"
				@setNotify="setNotify"
				@onLoading="$emit('onLoading')"
				@offLoading="$emit('offLoading')"
			></MonthlyPaymentkakao>
		</Modal>
	</div>
</template>

<script>
	import Search from "@/view/Layout/Search";
	import Excel from "@/components/Excel";
	import Modal from "@/components/Modal";
	import MonthlyPaymentkakao from "@/view/Monthly/MonthlyPaymentKakao";
	import MonthlyPaymentDetail from "@/view/Monthly/MonthlyPaymentDetail";
	import {codes} from "@/resources/config/codes";

	export default {

		name: 'MonthlySchedule'
		,
		components: {MonthlyPaymentDetail, MonthlyPaymentkakao, Modal, Excel, Search},
		data: function(){
			return {
                user: [],
				program: {
					name: '월세 내역'
					,top: true
					,title: true
					,bottom: false
				}
				,codes: codes
				,today: this.date.getToday('-')
				,search: {
					search_type: ''
					,sDate: this.date.getWeeklyDate('', '-').start
					,eDate: this.date.getWeeklyDate('', '-').end
					,word: ''
				}
				,search_option: {
					search_type: [
                        {name: '가맹점명', column: 1}
                        , {name: '대표자명', column: 2}
                        , {name: '입금자명', column: 3}
                        , {name: '아이디', column: 4}
                    ]
				}
				,items: []

				,item: {}
				,excel_data: {
					name: '월세 내역'
					,header: [
						{ key: 0, name: 'NO', column: 'idx'}
						,{ key: 0, name: '납입일', column: 'day'}
						,{ key: 0, name: '서비스', column: 'service_name'}
						,{ key: 0, name: '구분', column: 'typeName'}
						,{ key: 0, name: '사용자명', column: 'userName'}
						,{ key: 0, name: '납입타입', column: 'paymentTypeName'}
						,{ key: 0, name: '납입회차', column: 'paymentCurrentCount'}
						,{ key: 0, name: '전체회차', column: 'paymentTotalCount'}
						,{ key: 0, name: '승인금액', column: 'approvalAmount'}
						,{ key: 0, name: '비고', column: 'etc'}
					]
					,content: null
				}
				,is_excel: false
				,is_modal: false
				,modal_option: {

				}
				,is_modal3: false
				,modal_option3: {

				}
			}
		}
		,computed: {
			item_list: function(){
				return this.items.filter(function(item){
					return item.data.filter(function(sub){
						sub.day = item.day
					})
				})
			}
		}
		,methods: {
			getData: async function(){
				try{
					this.$layout.onLoading()
					this.search.startDate = this.search.sDate
					this.search.endDate = this.search.eDate
                    if(this.search.search_type) {
                        this.search.keywordType = this.search.search_type
                    } else {
                        delete this.search.keywordType
                    }
					const result = await this.$Axios({
						method: 'get'
						,url: 'monthly/log'
						,data: this.search
					})

					if(result.success){
						this.items = result.data
					}else{
						this.$layout.setNotify( { type: 'error', message: result.message})
					}
				}catch(e){
					this.$layout.setNotify( { type: 'error', message: this.$language.common.error})
					console.log(e)
				}finally {
					this.$layout.offLoading()
				}
			}
			,getSearch: function(){

				this.getData()
			}
			,toExcel: function(){
				let list = []
				let self = this
				this.items.filter(function(item){
					if(item.data){
						item.data.filter(function(cont){

							for(let i = 0; i < self.codes.monthly_service.length; i++){

								if(cont.service == self.codes.monthly_service[i].code){

									cont.service_name = self.codes.monthly_service[i].name
									cont.service_color = self.codes.monthly_service[i].color
									break
								}
							}
							cont.day = item.day
							list.push(cont)
						})
					}
				})
				this.excel_data.content = list
				this.is_excel = true
			}
			,prev: function(){
				let dates = this.date.getPrevWeelkyDate(this.search.sDate, '-')

				this.search.sDate = dates.start
				this.search.eDate = dates.end

				this.getSearch()
			}
			,next: function(){
				let dates = this.date.getNextWeelkyDate(this.search.sDate, '-')
				this.search.sDate = dates.start
				this.search.eDate = dates.end

				this.getSearch()
			}
			,toToday: function(){
				let dates = this.date.getWeeklyDate(this.today, '-')
				this.search.sDate = dates.start
				this.search.eDate = dates.end

				this.getSearch()
			}
			,toDetail: async function(item){
				this.close()
				this.item = item
				this.service = item.service
				this.is_modal = true
			}
			,close :function(){
				this.is_modal = false
				this.is_modal2 = false
				this.is_modal3 = false
			}
			,toKakao: function(){
				this.close()
				this.is_modal3 = true
			}
			,doConfirm: async function(){
				try{
					this.$layout.onLoading()
					const result = await this.$Axios({
						method: 'put'
						,url: 'monthly/payment/confirm'
						,data: {
							service: this.item.service
							,targetIdxList: this.item.idx
						}
					})

					if(result.success){
						this.close()
						this.getSearch()
						this.$layout.setNotify( { type: 'success', message: result.message})
					}else{
						this.$layout.setNotify( { type: 'error', message: result.message})
					}
				}catch(e){
					this.$layout.setNotify( { type: 'error', message: this.$language.common.error})
					console.log(e)
				}finally {
					this.close()
					this.$layout.offLoading()
				}
			}
			,setNotify: function({ type, message}){
				this.$layout.setNotify( { type: type, message: message })
			}
			,reset: function(){
				this.search = {
					search_type: ''
					,sDate: this.date.getWeeklyDate('', '-').start
					,eDate: this.date.getWeeklyDate('', '-').end
					,word: ''
				}

				this.getData()
			}
		}
		, created() {
            this.$layout = this.$root.$children[0].$children[0]
            this.user = this.$layout.user
            this.$layout.onLoad(this.program)
			this.getData()
		}
	}

</script>

<style>

</style>